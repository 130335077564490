/*!

 =========================================================
 * Atlantis Bootstrap Dashboard (Bootstrap 4)
 =========================================================

 * Product Page: http://www.themekita.com/
 * Copyright 2018 Theme Kita (http://www.themekita.com/)

 =========================================================

 * Modified by Muhammad Afifudin
 * Copyright 2020 Muhammad Afifudin (https://afiiif.github.io/)

*/

/*

[Table of contents]

* Background
* Typography
* Layouts
   + Body & Wrapper
   + Main Header
   + Sidebar
* Layouts Color
* Components
   + Breadcrumbs
   + Cards
     - Card Stats
     - Card Task
     - Card States
     - Card Posts
     - Card Pricing
     - Card Annoucement
     - Card Profile
     - Accordion
   + Inputs
   + Tables
   + Navbars
   + Navsearch
   + Badges
   + Dropdowns
   + Charts
   + Alerts
   + Buttons
   + Navtabs
   + Popovers
   + Progress
   + Paginations
   + Sliders
   + Modals
   + Timeline
   + Maps
   + Invoice
   + Messages
   + Tasks
   + Settings
* Plugins
   + jQueryUI
   + jQuery Scrollbar
   + Css Animate
   + Full Calendar
   + SweetAlert
   + Datatables

* Responsive

# [Color codes]

body-text-color: #575962
white-color: #ffffff
black-color: #191919
transparent-bg : transparent
default-color : #282a3c
primary-color : #177dff
secondary-color : #716aca
info-color : #36a3f7
success-color : #35cd3a
warning-color : #ffa534
danger-color : #f3545d

-------------------------------------------------------------------*/
*:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

/*   Typography    */
body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, a, .td-name, td, button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif; }

body {
  font-size: 14px;
  color: #575962; }

a {
  color: #1572E8; }
  a:hover, a:focus {
    color: #1269DB; }

h1,
.h1 {
  font-size: 1.725rem; }

h2,
.h2 {
  font-size: 1.35rem; }

h3,
.h3 {
  font-size: 1.1625rem; }

h4,
.h4 {
  font-size: 1.0375rem; }

h5,
.h5 {
  font-size: .9125rem; }

h6,
.h6 {
  font-size: .825rem; }

p {
  font-size: 14px;
  // line-height: 1.82;
  margin-bottom: 1rem;
  word-break: break-word; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.4; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
    color: inherit; }

small, .small {
  font-size: 13px; }

b, .b, strong, .strong {
  font-weight: 600; }

.page-pretitle {
  letter-spacing: .08em;
  text-transform: uppercase;
  color: #95aac9; }

.page-title {
  font-size: 23px;
  font-weight: 600;
  color: #444444;
  line-height: 30px;
  margin-bottom: 20px; }

.page-category {
  color: #444444;
  line-height: 1.8;
  margin-bottom: 25px; }

.text-primary, .text-primary a {
  color: #1572E8 !important; }
  .text-primary:hover, .text-primary a:hover {
    color: #1572E8 !important; }

.text-secondary, .text-secondary a {
  color: #6861CE !important; }
  .text-secondary:hover, .text-secondary a:hover {
    color: #6861CE !important; }

.text-info, .text-info a {
  color: #48ABF7 !important; }
  .text-info:hover, .text-info a:hover {
    color: #48ABF7 !important; }

.text-success, .text-success a {
  color: #31CE36 !important; }
  .text-success:hover, .text-success a:hover {
    color: #31CE36 !important; }

.text-green, .text-green a {
  color: #28a745 !important; }
  .text-green:hover, .text-green a:hover {
    color: #28a745 !important; }

.text-warning, .text-warning a {
  color: #FFAD46 !important; }
  .text-warning:hover, .text-warning a:hover {
    color: #FFAD46 !important; }

.text-danger, .text-danger a {
  color: #F25961 !important; }
  .text-danger:hover, .text-danger a:hover {
    color: #F25961 !important; }

label {
  color: #495057 !important;
  font-size: 14px !important; }

.text-small {
  font-size: 11px; }


/*    Layouts     */
body {
  min-height: 100vh;
  position: relative;
  background: #f9fbfd; }


.wrapper {
  min-height: 100vh;
  position: relative;
  top: 0;
  height: 100vh; }

.main-header {
  background: #ffffff;
  min-height: 60px;
  width: 100%;
  position: fixed;
  z-index: 1001;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5); }
  .main-header .navbar-header {
    min-height: 62px; }
    .main-header .navbar-header .btn-toggle {
      margin-right: 30px;
      margin-left: 20px; }

.logo-header {
  float: left;
  width: 250px;
  height: 62px;
  line-height: 60px;
  color: #333333;
  z-index: 1001;
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1001;
  display: flex;
  align-items: center;
  position: relative;
  transition: all .3s; }
  .logo-header .big-logo {
    margin-right: 8px; }
    .logo-header .big-logo:hover {
      text-decoration: none; }
    .logo-header .big-logo .logo-img {
      width: 35px;
      height: 35px; }
  .logo-header .logo {
    color: #575962;
    opacity: 1;
    position: relative;
    height: 100%; }
    .logo-header .logo:hover {
      text-decoration: none; }
    .logo-header .logo .navbar-brand {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-right: 0px; }
  .logo-header .nav-toggle {
    position: absolute;
    top: 0;
    right: 18px;
    z-index: 5; }
  .logo-header .navbar-toggler {
    padding-left: 0px;
    padding-right: 0px;
    opacity: 0;
    display: none; }
    .logo-header .navbar-toggler .navbar-toggler-icon {
      height: 1em;
      width: 1em;
      color: #545454;
      font-size: 22px; }
  .logo-header .more {
    background: transparent;
    border: 0;
    font-size: 22px;
    padding: 0;
    opacity: 0;
    width: 0;
    display: none; }


.logo-header[data-background-color] .navbar-toggler .navbar-toggler-icon {
  color: #ffffff !important; }
.logo-header[data-background-color] .more {
  color: #ffffff !important; }
.logo-header[data-background-color="white"] .navbar-toggler .navbar-toggler-icon, .logo-header[data-background-color="white"] .more, .logo-header[data-background-color="white"] .btn-toggle {
  color: #8d9498 !important; }

.logo-header[data-background-color="white"] {
  background: #ffffff !important; }

.navbar-header[data-background-color="white"] {
  background: #ffffff !important; }

.logo-header[data-background-color="dark"] {
  background: #1a2035 !important; }

.logo-header[data-background-color="dark2"] {
  background: #1f283e !important; }

.navbar-header[data-background-color="dark"] {
  background: #1a2035 !important; }

.navbar-header[data-background-color="dark2"] {
  background: #1f283e !important; }

.logo-header[data-background-color="blue"] {
  background: #1572E8 !important; }

.logo-header[data-background-color="blue2"] {
  background: #1269DB !important; }

.navbar-header[data-background-color="blue"] {
  background: #1572E8 !important; }

.navbar-header[data-background-color="blue2"] {
  background: #1269DB !important; }

.logo-header[data-background-color="purple"] {
  background: #6861CE !important; }

.logo-header[data-background-color="purple2"] {
  background: #5C55BF !important; }

.navbar-header[data-background-color="purple"] {
  background: #6861CE !important; }

.navbar-header[data-background-color="purple2"] {
  background: #5C55BF !important; }

.logo-header[data-background-color="light-blue"] {
  background: #48ABF7 !important; }

.logo-header[data-background-color="light-blue2"] {
  background: #3697E1 !important; }

.navbar-header[data-background-color="light-blue"] {
  background: #48ABF7 !important; }

.navbar-header[data-background-color="light-blue2"] {
  background: #3697E1 !important; }

.logo-header[data-background-color="green"] {
  background: #31CE36 !important; }

.logo-header[data-background-color="green2"] {
  background: #2BB930 !important; }

.navbar-header[data-background-color="green"] {
  background: #31CE36 !important; }

.navbar-header[data-background-color="green2"] {
  background: #2BB930 !important; }

.logo-header[data-background-color="orange"] {
  background: #FFAD46 !important; }

.logo-header[data-background-color="orange2"] {
  background: #FF9E27 !important; }

.navbar-header[data-background-color="orange"] {
  background: #FFAD46 !important; }

.navbar-header[data-background-color="orange2"] {
  background: #FF9E27 !important; }

.logo-header[data-background-color="red"] {
  background: #F25961 !important; }

.logo-header[data-background-color="red2"] {
  background: #EA4d56 !important; }

.navbar-header[data-background-color="red"] {
  background: #F25961 !important; }

.navbar-header[data-background-color="red2"] {
  background: #EA4d56 !important; }


.border-dark {
  border-color: #202940 !important; }


/*     Card     */
.card, .card-light {
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  border: 0px; }
  .card .card-header, .card-light .card-header {
    padding: 1rem 1.25rem;
    background-color: transparent;
    border-bottom: 1px solid #ebecec !important; }
    .card .card-header:first-child, .card-light .card-header:first-child {
      border-radius: 0px; }
    .card .card-header .card-head-row, .card-light .card-header .card-head-row {
      display: flex;
      align-items: center; }
      .card .card-header .card-head-row .card-tools, .card-light .card-header .card-head-row .card-tools {
        margin-left: auto;
        float: right;
        padding-left: 15px; }
  .card .separator-solid, .card-light .separator-solid {
    border-top: 1px solid #ebecec;
    margin: 15px 0; }
  .card .separator-dashed, .card-light .separator-dashed {
    border-top: 1px dashed #ebecec;
    margin: 15px 0; }
  .card .separator-dot, .card-light .separator-dot {
    border-top: 1px dotted #ebecec;
    margin: 15px 0; }
  .card .full-width-separator, .card-light .full-width-separator {
    margin: 15px -20px 15px; }
  .card .b-b1, .card-light .b-b1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .card .card-body, .card-light .card-body {
    padding: 1.25rem; }
  .card .card-footer, .card-light .card-footer {
    background-color: transparent;
    line-height: 30px;
    border-top: 1px solid #ebecec !important;
    font-size: 13px; }
  .card .pull-in, .card-light .pull-in {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
    .card .pull-in.sparkline-fix, .card-light .pull-in.sparkline-fix {
      margin-left: -1.35rem;
      margin-right: -1.35rem;
      margin-bottom: -3px; }
  .card .chart-as-background, .card-light .chart-as-background {
    position: absolute;
    bottom: 0;
    width: calc(100% + 2px); }
  .card .card-action, .card-light .card-action {
    padding: 30px;
    background-color: transparent;
    line-height: 30px;
    border-top: 1px solid #ebecec !important;
    font-size: 14px; }
  .card .card-footer hr, .card-light .card-footer hr {
    margin-top: 5px;
    margin-bottom: 5px; }
  .card .card-footer .legend, .card-light .card-footer .legend {
    display: inline-block; }

@media screen and (max-width: 476px) {
  .card .card-header .card-head-row:not(.card-tools-still-right) {
    flex-direction: column;
    align-items: unset; }
    .card .card-header .card-head-row:not(.card-tools-still-right) .card-tools {
      margin-left: 0px;
      float: left;
      padding-left: 0px;
      padding-top: 10px; } }
.card.full-height {
  height: calc(100% - 30px); }

.card-space {
  padding: 0 30px; }
  .card-space > .card-header, .card-space > .card-body, .card-space > .card-footer, .card-space > .card-action {
    padding-left: 0px !important;
    padding-right: 0px !important; }

.card-with-nav .card-header {
  border-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important; }
.card-with-nav .card-body {
  padding: 15px 25px !important; }

.card-list {
  padding: 10px 0; }
  .card-list .item-list {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center; }
    .card-list .item-list .info-user {
      flex: 1; }
      .card-list .item-list .info-user .username, .card-list .item-list .info-user a.username {
        color: #1572E8;
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: 400; }
      .card-list .item-list .info-user .status {
        font-size: 11px;
        color: #7d7b7b; }

.card-title {
  margin: 0;
  color: #575962;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6; }
  .card-title a, .card-title a:hover, .card-title a:focus {
    color: #575962;
    text-decoration: none; }

.card-sub {
  display: block;
  margin: 5px 0 10px 0;
  font-size: .9rem;
  background: #f7f8fa;
  color: #575962;
  padding: 0.85rem 1.5rem;
  border-radius: 4px;
  line-height: 1.82; }

.card-category {
  margin-top: 8px;
  font-size: 14px;
  color: #8d9498;
  margin-bottom: 0px;
  word-break: normal; }

label {
  font-size: 14px;
  font-weight: 400;
  color: #8d9498;
  margin-bottom: 0px; }

.card-transparent {
  background: transparent !important;
  box-shadow: none;
  border-color: transparent !important; }


.border-transparent {
  border-color: transparent !important; }


/*     Form     */
.form-control {
  font-size: 14px;
  border-color: #ebedf2;
  padding: .6rem 1rem;
  height: inherit !important; }
  .form-control:focus {
    border-color: #3e93ff; }

/*     Form Control Sizes    */
.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem !important;
  font-size: 1.25rem !important; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem !important;
  font-size: .875rem !important;
  line-height: 1.5; }

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.7; }

.form-control:-moz-placeholder {
  color: inherit;
  opacity: 0.7; }

.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.7; }

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.7; }

.form-control::-ms-input-placeholder {
  color: inherit;
  opacity: 0.7; }

.input-group-text {
  border-color: #ebedf2 !important; }

.form-button-action {
  display: inline-flex; }

.form-check-label, .form-radio-label {
  margin-right: 15px; }

/*     CheckBox Input    */
.select-all-checkbox + .form-check-sign:before {
  background: #ccc !important;
  border-color: #ccc !important; }

.form-check [type="checkbox"]:not(:checked), .form-check [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }
.form-check [type="checkbox"]:not(:checked) + .form-check-sign, .form-check [type="checkbox"]:checked + .form-check-sign, .form-check [type="checkbox"] + .form-check-sign {
  position: relative;
  padding-left: 2em;
  color: #575962;
  cursor: pointer; }
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:before, .form-check [type="checkbox"]:checked + .form-check-sign:before, .form-check [type="checkbox"] + .form-check-sign:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background: transparent;
  border-radius: 4px; }
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:after, .form-check [type="checkbox"]:checked + .form-check-sign:after, .form-check [type="checkbox"] + .form-check-sign:after {
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 2px;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 1.3em;
  line-height: 0.8;
  color: #1572E8;
  transition: all .2s;
  font-family: 'Font Awesome 5 Solid'; }
.form-check [type="checkbox"]:not(:checked) + .form-check-sign:after {
  opacity: 0;
  transform: scale(0); }
.form-check [type="checkbox"]:checked + .form-check-sign {
  font-weight: 400; }
  .form-check [type="checkbox"]:checked + .form-check-sign:after {
    opacity: 1;
    transform: scale(1); }
.form-check [type="checkbox"]:disabled:not(:checked) + .form-check-sign:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd; }
.form-check [type="checkbox"]:disabled:checked + .form-check-sign:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd; }
.form-check [type="checkbox"]:disabled:checked + .form-check-sign:after {
  color: #999; }
.form-check [type="checkbox"]:disabled + .form-check-sign {
  color: #aaa; }
.form-check [type="checkbox"]:checked:focus + .form-check-sign:before, .form-check [type="checkbox"]:not(:checked):focus + .form-check-sign:before {
  border: 1px solid #ccc; }

.form-check-sign:hover:before {
  border: 1px solid #ccc !important; }

.form-check {
  padding-left: 0.75rem; }

.form-check-input {
  position: relative;
  margin-top: .3rem; }

/*     Radio Input    */
.form-radio [type="radio"]:not(:checked), .form-radio [type="radio"]:checked {
  position: absolute;
  left: -9999px; }
.form-radio [type="radio"]:not(:checked) + .form-radio-sign, .form-radio [type="radio"]:checked + .form-radio-sign {
  color: #575962;
  position: relative;
  padding-left: 2em;
  cursor: pointer;
  line-height: 22px;
  font-weight: 400; }
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:before {
  content: "\f18a";
  font-size: 22px;
  font-family: LineAwesome;
  position: absolute;
  left: 0;
  top: auto;
  background: transparent;
  line-height: 1;
  color: #bbb; }
.form-radio [type="radio"]:checked + .form-radio-sign:before {
  content: "\f18a";
  font-size: 22px;
  font-family: LineAwesome;
  position: absolute;
  left: 0;
  top: auto;
  background: transparent;
  line-height: 1;
  display: none; }
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:after, .form-radio [type="radio"]:checked + .form-radio-sign:after {
  content: "\f1bc";
  position: absolute;
  left: 0px;
  top: auto;
  text-align: center;
  font-size: 22px;
  color: #4D7CFE;
  transition: all .2s;
  line-height: 1;
  font-family: LineAwesome; }
.form-radio [type="radio"]:not(:checked) + .form-radio-sign:after {
  opacity: 0;
  transform: scale(0); }
.form-radio [type="radio"]:checked + .form-radio-sign {
  font-weight: 400; }
  .form-radio [type="radio"]:checked + .form-radio-sign:after {
    opacity: 1;
    transform: scale(1); }
.form-radio [type="radio"]:disabled:not(:checked) + .form-radio-sign:before {
  box-shadow: none;
  opacity: 0.65; }
.form-radio [type="radio"]:disabled:checked + .form-radio-sign:before {
  box-shadow: none;
  opacity: 0.65; }
.form-radio [type="radio"]:disabled:checked + .form-radio-sign:after {
  opacity: 0.65; }
.form-radio [type="radio"]:disabled + .form-radio-sign {
  color: #aaa;
  opacity: 0.65; }
.form-radio [type="radio"]:checked:focus + .form-radio-sign:before, .form-radio [type="radio"]:not(:checked):focus + .form-radio-sign:before {
  border: 1px solid #ccc; }

.form-radio {
  padding-left: 0.75rem; }

.form-radio-input {
  position: relative;
  margin-top: .3rem; }

/*      Custom Checkbox      */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1572E8; }
.custom-checkbox.checkbox-default .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1a2035; }
.custom-checkbox.checkbox-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1572E8; }
.custom-checkbox.checkbox-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6861CE; }
.custom-checkbox.checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #48ABF7; }
.custom-checkbox.checkbox-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #31CE36; }
.custom-checkbox.checkbox-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFAD46; }
.custom-checkbox.checkbox-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #F25961; }

/*      Label      */
.col-form-label {
  line-height: 1.8; }

.required-label {
  color: red; }

.label-align-left {
  text-align: left; }

.label-align-right {
  text-align: right; }

.label-align-center {
  text-align: center; }

/*     Input    */
.form-group, .form-check {
  margin-bottom: 0;
  padding: 10px; }

.form-group label, .form-check label {
  margin-bottom: .5rem;
  color: #495057;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap; }

.form-group-default {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  width: 100%;
  -webkit-transition: background-color .2s ease;
  transition: background-color .2s ease;
  margin-bottom: 15px; }
  .form-group-default label {
    opacity: 1;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    margin: 0;
    display: block;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease; }
  .form-group-default label:not(.error) {
    font-size: 10.5px !important;
    letter-spacing: .06em;
    text-transform: uppercase;
    font-weight: 400; }
  .form-group-default .form-control {
    border: 0;
    min-height: 25px;
    padding: 0;
    margin-top: 6px;
    background: 0 0;
    font-size: 14px; }
  .form-group-default select.form-control:not([size]):not([multiple]) {
    height: unset !important; }
  .form-group-default.active {
    border-color: rgba(0, 0, 0, 0.1) !important;
    background-color: #f0f0f0; }
    .form-group-default.active label {
      opacity: 0.5; }

.form-floating-label {
  position: relative; }
  .form-floating-label .placeholder {
    position: absolute;
    padding: .375rem .75rem;
    transition: all .2s;
    opacity: 0.8;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: 400;
    top: 12px; }
  .form-floating-label .form-control:focus + .placeholder, .form-floating-label .form-control:valid + .placeholder, .form-floating-label .form-control.filled + .placeholder {
    font-size: 85% !important;
    transform: translate3d(0, -10px, 0);
    top: 0;
    opacity: 1;
    padding: .375rem 0 .75rem;
    font-weight: 600; }
  .form-floating-label .form-control.filled + .placeholder {
    color: #1572E8 !important; }
  .form-floating-label .form-control ::-webkit-input-placeholder {
    color: transparent; }
  .form-floating-label .form-control :-moz-placeholder {
    color: transparent; }
  .form-floating-label .form-control ::-moz-placeholder {
    color: transparent; }
  .form-floating-label .form-control :-ms-input-placeholder {
    color: transparent; }
  .form-floating-label .input-border-bottom + .placeholder {
    padding: .375rem 0 .75rem; }

.form-inline label {
  margin-bottom: 0 !important; }

.input-fixed {
  width: 200px; }

.form-control.input-full {
  width: 100% !important; }

.has-success label {
  color: #31CE36 !important; }
.has-success .form-control {
  border-color: #31CE36 !important;
  color: #31CE36 !important; }
.has-success .input-group-text {
  border-color: #31CE36 !important;
  background: #31CE36 !important;
  color: #ffffff !important; }

.has-error label {
  color: #F25961 !important; }
.has-error .form-control {
  border-color: #F25961 !important;
  color: #F25961 !important; }
.has-error .input-group-text {
  border-color: #F25961 !important;
  background: #F25961 !important;
  color: #ffffff !important; }

.input-group label.error, .input-group label.success {
  width: 100%;
  order: 100; }

.custom-control {
  position: relative; }
  .custom-control.custom-radio, .custom-control.custom-checkbox {
    margin-bottom: 0;
    padding-left: 2em;
    cursor: pointer;
    line-height: 24px;
    margin-right: 25px;
    display: inline-block; }
    .custom-control.custom-radio label.error, .custom-control.custom-radio label.success, .custom-control.custom-checkbox label.error, .custom-control.custom-checkbox label.success {
      position: absolute;
      width: 100vh;
      top: 23px;
      left: 0; }

.has-feedback {
  position: relative; }

.form-control-feedback {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px; }

.has-success .form-control-feedback {
  color: #31CE36; }

.has-error .form-control-feedback {
  color: #F25961; }

.input-group.has-icon {
  border-radius: .25rem;
  border: 1px solid #ced4da; }
.input-group.has-success, .input-group.has-icon.has-success {
  border: 1px solid #31CE36 !important;
  color: #31CE36; }
.input-group.has-error {
  border: 1px solid #F25961 !important;
  color: #F25961; }
.input-group.has-icon.has-error {
  border: 1px solid #F25961 !important;
  color: #F25961; }
.input-group.has-icon .form-control {
  border-radius: .25rem;
  border: 0px;
  position: relative; }
.input-group.has-icon .input-group-icon {
  background: transparent;
  border: 0px; }

.input-square {
  border-radius: 0px !important; }

.input-pill {
  border-radius: 1.3rem !important; }

.input-solid {
  background: #e8e8e8 !important;
  border-color: #e8e8e8 !important; }

.input-border-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0px;
  padding: .75rem 0;
  background: transparent !important; }


/*      Custom Dropzone      */
.dropzone {
  padding: 70px 60px 80px !important;
  border: 2px dashed rgba(0, 0, 0, 0.13) !important;
  background: transparent !important; }
  .dropzone:hover {
    background: #fafafa !important;
    transition: all .5s !important; }
  .dropzone .dz-message .icon {
    margin-bottom: 15px;
    font-size: 39px; }
  .dropzone .dz-message .message {
    font-size: 34px;
    font-weight: 200; }
  .dropzone .dz-message .note {
    font-size: 16px;
    margin-top: 18px;
    font-weight: 300; }


/*     Table    */
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  vertical-align: middle; }
.table > tbody > tr > td, .table > tbody > tr > th {
  padding: 8px; }
.table > tfoot > tr > td, .table > tfoot > tr > th {
  padding: 8px; }
.table thead th {
  border-bottom-width: 2px;
  font-weight: 600; }
.table td, .table th {
  font-size: 14px;
  border-top-width: 0px;
  border-bottom: 1px solid;
  border-color: #ebedf2 !important;
  // padding: 0 25px !important;
  // height: 60px;
  // vertical-align: middle !important;
}

/* table full-width */
.table-full-width {
  margin-left: -15px;
  margin-right: -15px; }

/* table bordered states */
.table-bordered-bd-default td, .table-bordered-bd-default th {
  border: 1px solid #1a2035 !important; }

.table-bordered-bd-primary td, .table-bordered-bd-primary th {
  border: 1px solid #1572E8 !important; }

.table-bordered-bd-secondary td, .table-bordered-bd-secondary th {
  border: 1px solid #6861CE !important; }

.table-bordered-bd-info td, .table-bordered-bd-info th {
  border: 1px solid #48ABF7 !important; }

.table-bordered-bd-success td, .table-bordered-bd-success th {
  border: 1px solid #31CE36 !important; }

.table-bordered-bd-warning td, .table-bordered-bd-warning th {
  border: 1px solid #FFAD46 !important; }

.table-bordered-bd-danger td, .table-bordered-bd-danger th {
  border: 1px solid #F25961 !important; }

.table-striped td, .table-striped th {
  border-top: 0 !important;
  border-bottom: 0 !important; }

/* table head background states*/
.table-head-bg-default thead {
  border: 1px solid #1a2035 !important; }

.table-head-bg-primary thead {
  border: 1px solid #1572E8 !important; }

.table-head-bg-secondary thead {
  border: 1px solid #6861CE !important; }

.table-head-bg-info thead {
  border: 1px solid #48ABF7 !important; }

.table-head-bg-success thead {
  border: 1px solid #31CE36 !important; }

.table-head-bg-warning thead {
  border: 1px solid #FFAD46 !important; }

.table-head-bg-danger thead {
  border: 1px solid #F25961 !important; }

.table-head-bg-default thead th, .table-striped-bg-default tbody tr:nth-of-type(odd) {
  background: #1a2035 !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-primary thead th, .table-striped-bg-primary tbody tr:nth-of-type(odd) {
  background: #1572E8 !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-secondary thead th, .table-striped-bg-secondary tbody tr:nth-of-type(odd) {
  background: #6861CE !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-info thead th, .table-striped-bg-info tbody tr:nth-of-type(odd) {
  background: #48ABF7 !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-success thead th, .table-striped-bg-success tbody tr:nth-of-type(odd) {
  background: #31CE36 !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-warning thead th, .table-striped-bg-warning tbody tr:nth-of-type(odd) {
  background: #FFAD46 !important;
  color: #ffffff !important;
  border: 0px !important; }

.table-head-bg-danger thead th, .table-striped-bg-danger tbody tr:nth-of-type(odd) {
  background: #F25961 !important;
  color: #ffffff !important;
  border: 0px !important; }

/* table-responsive */
.table-responsive {
  width: 100% !important;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

/*     Navbar     */
.navbar .navbar-nav .nav-item {
  margin-right: 7px; }
  .navbar .navbar-nav .nav-item:last-child {
    margin-right: 0px; }
  .navbar .navbar-nav .nav-item .nav-link {
    display: inline-block;
    vertical-align: middle;
    color: #666;
    letter-spacing: 0.04em;
    padding: 10px;
    border-radius: 3px;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    text-align: center; }
    .navbar .navbar-nav .nav-item .nav-link:hover, .navbar .navbar-nav .nav-item .nav-link:focus {
      background: #eee !important; }
    .navbar .navbar-nav .nav-item .nav-link i {
      font-size: 18px;
      vertical-align: middle;
      line-height: 1 !important; }
  .navbar .navbar-nav .nav-item.active .nav-link {
    background: #eee !important; }

.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: auto;
  right: 0; }

.dropdown-item {
  font-size: 13px; }

.navbar .navbar-nav .notification {
  position: absolute;
  background-color: #31CE36;
  text-align: center;
  border-radius: 10px;
  min-width: 17px;
  height: 17px;
  font-size: 10px;
  color: #ffffff;
  font-weight: 300;
  line-height: 17px;
  top: 3px;
  right: 3px;
  letter-spacing: -1px; }

.navbar-header {
  padding: 0px 15px; }
  .navbar-header .dropdown-toggle::after {
    margin-left: 0; }

.profile-pic:hover, .profile-pic:focus {
  text-decoration: none; }

.navbar-header .dropdown-toggle::after {
  vertical-align: middle;
  color: #555; }

.hidden-caret .dropdown-toggle::after {
  display: none !important; }

.profile-pic span {
  font-size: 13px;
  font-weight: 300;
  padding: 0 10px;
  color: #555; }

.navbar[class*="bg-"] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .navbar[class*="bg-"] .navbar-brand {
    color: #ffffff; }
  .navbar[class*="bg-"] .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link {
    color: #ffffff; }
    .navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link.disabled {
      color: #d8d8d8 !important; }
    .navbar[class*="bg-"] .navbar-nav > .nav-item > .nav-link:hover {
      background: rgba(255, 255, 255, 0.22) !important; }
  .navbar[class*="bg-"] .navbar-nav > .nav-item.active > .nav-link {
    background: rgba(255, 255, 255, 0.22) !important; }
  .navbar[class*="bg-"] .btn-toggle {
    background: rgba(19, 19, 19, 0.25) !important;
    color: #ffffff !important; }
  .navbar[class*="bg-"] .nav-search .input-group {
    border: 0;
    background: rgba(19, 19, 19, 0.25) !important; }
    .navbar[class*="bg-"] .nav-search .input-group .form-control {
      color: #ffffff !important; }
  .navbar[class*="bg-"] .nav-search .search-icon {
    color: #ffffff !important; }

.row-nav-line {
  margin-left: -20px;
  margin-right: -20px; }

.nav.nav-line {
  width: 100%;
  border-bottom: 1px solid #f1f1f1; }
  .nav.nav-line .nav-link {
    padding: 15px 20px;
    color: #575962;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600; }
    .nav.nav-line .nav-link:hover, .nav.nav-line .nav-link:focus {
      color: #1572E8;
      border-width: 0px; }
    .nav.nav-line .nav-link.active {
      border-width: 0;
      background-color: transparent;
      color: #1572E8;
      border-bottom: 2px solid #1572E8;
      border-radius: 0px; }
  .nav.nav-line.nav-color-default .nav-link:hover, .nav.nav-line.nav-color-default .nav-link:focus {
    color: #1a2035; }
  .nav.nav-line.nav-color-default .nav-link.active {
    color: #1a2035;
    border-color: #1a2035; }
  .nav.nav-line.nav-color-primary .nav-link:hover, .nav.nav-line.nav-color-primary .nav-link:focus {
    color: #1572E8; }
  .nav.nav-line.nav-color-primary .nav-link.active {
    color: #1572E8;
    border-color: #1572E8; }
  .nav.nav-line.nav-color-secondary .nav-link:hover, .nav.nav-line.nav-color-secondary .nav-link:focus {
    color: #6861CE; }
  .nav.nav-line.nav-color-secondary .nav-link.active {
    color: #6861CE;
    border-color: #6861CE; }
  .nav.nav-line.nav-color-info .nav-link:hover, .nav.nav-line.nav-color-info .nav-link:focus {
    color: #48ABF7; }
  .nav.nav-line.nav-color-info .nav-link.active {
    color: #48ABF7;
    border-color: #48ABF7; }
  .nav.nav-line.nav-color-success .nav-link:hover, .nav.nav-line.nav-color-success .nav-link:focus {
    color: #31CE36; }
  .nav.nav-line.nav-color-success .nav-link.active {
    color: #31CE36;
    border-color: #31CE36; }
  .nav.nav-line.nav-color-danger .nav-link:hover, .nav.nav-line.nav-color-danger .nav-link:focus {
    color: #F25961; }
  .nav.nav-line.nav-color-danger .nav-link.active {
    color: #F25961;
    border-color: #F25961; }
  .nav.nav-line.nav-color-warning .nav-link:hover, .nav.nav-line.nav-color-warning .nav-link:focus {
    color: #FFAD46; }
  .nav.nav-line.nav-color-warning .nav-link.active {
    color: #FFAD46;
    border-color: #FFAD46; }
  .nav.nav-line.nav-color-light .nav-link:hover, .nav.nav-line.nav-color-light .nav-link:focus {
    color: #ffffff; }
  .nav.nav-line.nav-color-light .nav-link.active {
    color: #ffffff;
    border-color: #ffffff; }
  .nav.nav-line.nav-color-light .nav-link {
    color: #f1f1f1; }


/*     Badge    */
.badge {
  border-radius: 50px;
  margin-left: auto;
  line-height: 1;
  padding: 6px 10px;
  vertical-align: middle;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #ddd; }

[class*="badge-"]:not(.badge-count) {
  border: 0px !important; }

.badge-default {
  background: #1a2035;
  color: #ffffff !important; }

.badge-primary {
  background: #1572E8; }

.badge-secondary {
  background: #6861CE; }

.badge-info {
  background: #48ABF7; }

.badge-success {
  background-color: #31CE36; }

.badge-warning {
  background: #FFAD46;
  color: #ffffff !important; }

.badge-danger {
  background-color: #F25961; }

/*     Dropdown    */
.dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;
  padding-bottom: 8px;
  margin-top: 3px; }


/*     Alert    */
.alert {
  border: 0px;
  position: relative;
  padding: .95rem 1.25rem;
  border-radius: 1px;
  color: inherit;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  -moz-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26); }
  .alert [data-notify="icon"] {
    display: block; }
    .alert [data-notify="icon"]::before {
      line-height: 35px;
      font-size: 22px;
      display: block;
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 35px;
      height: 35px;
      border-radius: 30px;
      text-align: center;
      color: #fff; }
  .alert [data-notify="title"] {
    display: block;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px; }
  .alert [data-notify="message"] {
    font-size: 13px;
    color: #908e8e; }
  .alert .close {
    background: rgba(255, 255, 255, 0.8);
    width: 25px;
    height: 25px;
    line-height: 25px;
    top: 12px !important;
    border-radius: 50%; }

/*    Alert States    */
.alert-default {
  border-left: 4px solid #1a2035; }
  .alert-default [data-notify="icon"]:before {
    background: #1a2035; }

.alert-primary {
  border-left: 4px solid #1572E8; }
  .alert-primary [data-notify="icon"]:before {
    background: #1572E8; }

.alert-secondary {
  border-left: 4px solid #6861CE; }
  .alert-secondary [data-notify="icon"]:before {
    background: #6861CE; }

.alert-info {
  border-left: 4px solid #48ABF7; }
  .alert-info [data-notify="icon"]:before {
    background: #48ABF7; }

.alert-success {
  border-left: 4px solid #31CE36; }
  .alert-success [data-notify="icon"]:before {
    background: #31CE36; }

.alert-warning {
  border-left: 4px solid #FFAD46; }
  .alert-warning [data-notify="icon"]:before {
    background: #FFAD46; }

.alert-danger {
  border-left: 4px solid #F25961; }
  .alert-danger [data-notify="icon"]:before {
    background: #F25961; }

/*    Button    */
.btn {
  padding: .65rem 1.4rem;
  font-size: 14px;
  opacity: 1;
  border-radius: 3px; }
  .btn:hover, .btn:focus {
    opacity: 0.9;
    transition: all .3s; }
  .btn .btn-label {
    display: inline-block; }
    .btn .btn-label i {
      font-size: 16px;
      vertical-align: middle;
      margin-right: 2px;
      margin-left: -2px;
      line-height: 0;
      margin-top: -2.5px; }
    .btn .btn-label.just-icon i {
      margin-left: 0 !important;
      margin-right: 0px !important; }

/*     Button Sizes     */
.btn-lg {
  font-size: 15px;
  border-radius: 3px;
  padding: 12.5px 27.5px;
  font-weight: 400; }
  .btn-lg .btn-label i {
    font-size: 27px;
    vertical-align: middle;
    margin-right: 2px;
    margin-left: -7px;
    line-height: 0;
    margin-top: -2.5px; }
  .btn-lg .btn-label.just-icon i {
    margin-left: 0 !important;
    margin-right: 0px !important; }

.btn-sm {
  font-size: 11px;
  padding: 7px 13px; }

.btn-xs {
  font-size: 10px;
  padding: 5px 9px; }

.btn.disabled:hover, .btn:hover:disabled {
  opacity: 0.65; }

/*      Button Icon        */
.btn-icon {
  font-size: .9375rem;
  height: 2.5125rem;
  line-height: normal;
  min-width: 2.5125rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 2.5125rem; }
  .btn-icon.btn-lg {
    height: 2.75rem;
    min-width: 2.75rem;
    width: 2.75rem; }
  .btn-icon.btn-sm {
    height: 2rem;
    min-width: 2rem;
    width: 2rem; }
  .btn-icon.btn-xs {
    height: 1.6875rem;
    min-width: 1.6875rem;
    width: 1.6875rem; }

/*      Button States      */
.btn-white {
  background: #ffffff !important;
  color: #1a2035 !important; }
  .btn-white:hover, .btn-white:focus, .btn-white:disabled {
    background: #ffffff !important;
    color: #1a2035 !important; }

.btn-default {
  background: #1a2035 !important;
  color: #ffffff !important; }
  .btn-default:hover, .btn-default:focus, .btn-default:disabled {
    background: #1a2035 !important;
    color: #ffffff !important; }

.btn-primary {
  background: #1572E8 !important;
  border-color: #1572E8 !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:disabled {
    background: #1572E8 !important;
    border-color: #1572E8 !important; }

.btn-secondary {
  background: #6861CE !important;
  border-color: #6861CE !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:disabled {
    background: #6861CE !important;
    border-color: #6861CE !important; }

.btn-info {
  background: #48ABF7 !important;
  border-color: #48ABF7 !important; }
  .btn-info:hover, .btn-info:focus, .btn-info:disabled {
    background: #48ABF7 !important;
    border-color: #48ABF7 !important; }

.btn-success {
  background: #31CE36 !important;
  border-color: #31CE36 !important; }
  .btn-success:hover, .btn-success:focus, .btn-success:disabled {
    background: #31CE36 !important;
    border-color: #31CE36 !important; }

.btn-warning {
  background: #FFAD46 !important;
  border-color: #FFAD46 !important;
  color: #ffffff !important; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:disabled {
    background: #FFAD46 !important;
    border-color: #FFAD46 !important;
    color: #ffffff !important; }

.btn-danger {
  background: #F25961 !important;
  border-color: #F25961 !important; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:disabled {
    background: #F25961 !important;
    border-color: #F25961 !important; }

.btn-light {
  background: #ffffff !important;
  border-color: transparent; }
  .btn-light:hover, .btn-light:focus, .btn-light:disabled {
    background: #ebecec !important;
    border-color: transparent; }

.btn-dropdown-card-header {
  padding: 0;
  background: transparent;
  color: inherit;
  font-size: 15px; }
  .btn-dropdown-card-header:after {
    display: none; }

/*      Button Border     */
.btn-border {
  background: transparent !important; }
  .btn-border:hover, .btn-border:focus {
    background: transparent !important; }
  .btn-border.btn-white {
    color: #ffffff !important;
    border: 1px solid #ffffff !important; }
  .btn-border.btn-default {
    color: #1a2035 !important;
    border: 1px solid #1a2035 !important; }
  .btn-border.btn-primary {
    color: #1572E8 !important;
    border: 1px solid #1572E8 !important; }
  .btn-border.btn-secondary {
    color: #6861CE !important;
    border: 1px solid #6861CE !important; }
  .btn-border.btn-info {
    color: #48ABF7 !important;
    border: 1px solid #48ABF7 !important; }
  .btn-border.btn-success {
    color: #31CE36 !important;
    border: 1px solid #31CE36 !important; }
  .btn-border.btn-warning {
    color: #FFAD46 !important;
    border: 1px solid #FFAD46 !important; }
  .btn-border.btn-danger {
    color: #F25961 !important;
    border: 1px solid #F25961 !important; }
  .btn-border.btn-light {
    border: 1px solid #efefef;
    background: #fff !important; }

/*      Button Rounded      */
.btn-round {
  border-radius: 100px !important; }

/*      Button Link      */
.btn-link {
  border: 0 !important;
  background: transparent !important; }
  .btn-link:hover, .btn-link:focus {
    text-decoration: underline !important;
    background: transparent !important;
    border: 0 !important; }
  .btn-link.btn-default {
    color: #1a2035 !important; }
    .btn-link.btn-default:hover {
      color: #1a2035 !important; }
  .btn-link.btn-primary {
    color: #1572E8 !important; }
    .btn-link.btn-primary:hover {
      color: #1572E8 !important; }
  .btn-link.btn-secondary {
    color: #6861CE !important; }
    .btn-link.btn-secondary:hover {
      color: #6861CE !important; }
  .btn-link.btn-info {
    color: #48ABF7 !important; }
    .btn-link.btn-info:hover {
      color: #48ABF7 !important; }
  .btn-link.btn-success {
    color: #31CE36 !important; }
    .btn-link.btn-success:hover {
      color: #31CE36 !important; }
  .btn-link.btn-warning {
    color: #FFAD46 !important; }
    .btn-link.btn-warning:hover {
      color: #FFAD46 !important; }
  .btn-link.btn-danger {
    color: #F25961 !important; }
    .btn-link.btn-danger:hover {
      color: #F25961 !important; }

.toggle-on.btn {
  color: #ffffff !important; }

.toggle-handle {
  background: #ffffff !important; }
  .toggle-handle:hover {
    background: #ffffff !important; }

.btn-round .toggle-handle {
  border-radius: 50px; }

.btn-rounded {
  border-radius: 60px !important; }

.btn-full {
  width: 100%; }

.btn-no-radius {
  border-radius: 0px; }


/*     Pagination     */
.pagination > li > a, .pagination > li:first-child > a, .pagination > li:last-child > a, .pagination > li > span, .pagination > li:first-child > span, .pagination > li:last-child > span {
  border-radius: 100px !important;
  margin: 0 2px;
  color: #777777;
  border-color: #ddd; }
.pagination.pg-default > li.active > a, .pagination.pg-default > li.active:first-child > a, .pagination.pg-default > li.active:last-child > a, .pagination.pg-default > li.active > span, .pagination.pg-default > li.active:first-child > span, .pagination.pg-default > li.active:last-child > span {
  background: #1a2035;
  border-color: #1a2035;
  color: #ffffff; }
.pagination.pg-primary > li.active > a, .pagination.pg-primary > li.active:first-child > a, .pagination.pg-primary > li.active:last-child > a, .pagination.pg-primary > li.active > span, .pagination.pg-primary > li.active:first-child > span, .pagination.pg-primary > li.active:last-child > span {
  background: #1572E8;
  border-color: #1572E8;
  color: #ffffff; }
.pagination.pg-secondary > li.active > a, .pagination.pg-secondary > li.active:first-child > a, .pagination.pg-secondary > li.active:last-child > a, .pagination.pg-secondary > li.active > span, .pagination.pg-secondary > li.active:first-child > span, .pagination.pg-secondary > li.active:last-child > span {
  background: #6861CE;
  border-color: #6861CE;
  color: #ffffff; }
.pagination.pg-info > li.active > a, .pagination.pg-info > li.active:first-child > a, .pagination.pg-info > li.active:last-child > a, .pagination.pg-info > li.active > span, .pagination.pg-info > li.active:first-child > span, .pagination.pg-info > li.active:last-child > span {
  background: #48ABF7;
  border-color: #48ABF7;
  color: #ffffff; }
.pagination.pg-success > li.active > a, .pagination.pg-success > li.active:first-child > a, .pagination.pg-success > li.active:last-child > a, .pagination.pg-success > li.active > span, .pagination.pg-success > li.active:first-child > span, .pagination.pg-success > li.active:last-child > span {
  background: #31CE36;
  border-color: #31CE36;
  color: #ffffff; }
.pagination.pg-warning > li.active > a, .pagination.pg-warning > li.active:first-child > a, .pagination.pg-warning > li.active:last-child > a, .pagination.pg-warning > li.active > span, .pagination.pg-warning > li.active:first-child > span, .pagination.pg-warning > li.active:last-child > span {
  background: #FFAD46;
  border-color: #FFAD46;
  color: #ffffff; }
.pagination.pg-danger > li.active > a, .pagination.pg-danger > li.active:first-child > a, .pagination.pg-danger > li.active:last-child > a, .pagination.pg-danger > li.active > span, .pagination.pg-danger > li.active:first-child > span, .pagination.pg-danger > li.active:last-child > span {
  background: #F25961;
  border-color: #F25961;
  color: #ffffff; }


/*      Modal     */
.modal .bg-default .modal-title, .modal .bg-primary .modal-title, .modal .bg-secondary .modal-title, .modal .bg-info .modal-title, .modal .bg-success .modal-title, .modal .bg-warning .modal-title, .modal .bg-danger .modal-title {
  color: #ffffff !important; }

.modal-content {
  border-radius: .4rem !important;
  border: 0 !important; }


/*      Datatable     */
table.dataTable {
  clear: both;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  max-width: none !important;
  border-collapse: separate !important; }
  table.dataTable td, table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
  table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    text-align: center; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap; }
div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block; }
div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left; }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto; }
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.55em;
  white-space: nowrap; }
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end; }
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0; }

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px; }
table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none; }
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.5; }
table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
  font-size: 15px; }
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
  font-size: 15px; }
table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1; }
table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0; }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important; }
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none; }
  div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }
div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box; }
  div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none; }

div.dataTables_wrapper div div.dataTables_paginate ul.pagination li a {
        font-size: 12px; }

@media screen and (max-width: 575.98px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
    margin-top: 11px;
    margin-bottom: 10px; }
  div.dataTables_wrapper div div.dataTables_paginate ul.pagination {
    flex-wrap: wrap;
    justify-content: center; }
  //   div.dataTables_wrapper div div.dataTables_paginate ul.pagination li {
  //     margin-bottom: 10px; }
      div.dataTables_wrapper div div.dataTables_paginate ul.pagination li a {
        font-size: 12px; } }
table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px; }
table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em; }
table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
  top: 5px; }
table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0; }
table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0; }
table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0; }

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0; }
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child, div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-left: 0;
    padding-right: 0; }

/*     Responsive     */
@media screen and (max-width: 575.98px) {
  .row-card-no-pd [class*=col-] .card:before {
    width: calc(100% - 30px) !important;
    right: 15px !important;
    height: 1px !important; }
  .row-card-no-pd [class*=col-]:first-child .card:before {
    display: none !important; } }
@media screen and (min-width: 992px) {
  .main-panel.full-height > .content, .main-panel.full-height > .content-full {
    margin-top: 0; }
  .main-panel.full-height .navbar-header {
    min-height: 62px; }

  .logo-header {
    line-height: 57px; }
  }
@media screen and (max-width: 991.98px) {
  .main-header[data-background-color] .navbar-header {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

  .logo-header {
    display: flex;
    width: 100% !important;
    text-align: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px; }
    .logo-header .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
    .logo-header .navbar-toggler {
      height: 100%;
      margin-left: 0px !important;
      opacity: 1;
      display: block;
      order: 1; }
    .logo-header .more {
      opacity: 1;
      color: #545454;
      cursor: pointer;
      display: inline-block;
      line-height: 56px;
      order: 3;
      width: unset;
      margin-left: auto; }
    .logo-header .navbar-brand {
      position: unset !important;
      margin-right: 0px; }

  .navbar-header {
    position: absolute;
    width: 100%;
    transform: translate3d(0, -200px, 0) !important;
    transition: all .5s; }


  /* Dropzone */
  .dropzone {
    padding: 20px 15px !important; }
    .dropzone .dz-message .message {
      font-size: 23px; }
    .dropzone .dz-message .note {
      font-size: 15px; } }


@media screen and (max-width: 767.98px) {
  .wizard-container {
    margin-left: 15px;
    margin-right: 15px; }

    .main-panel .page-header .breadcrumbs {
      margin-left: 0px;
      padding-top: 15px;
      padding-left: 5px;
      padding-bottom: 0px;
      border-left: 0px; }
    .main-panel .page-header .btn-group-page-header {
      position: absolute;
      right: 0px; }

  .footer .container-fluid {
    flex-direction: column; }
    .footer .container-fluid .copyright {
      margin-left: 0 !important;
      margin-top: 10px;
      margin-bottom: 15px; } }
@media screen and (max-width: 575.98px) {
  #chart-container {
    min-height: 250px; }

  .form-check-inline {
    display: flex;
    flex-direction: column;
    align-items: left; }


  }

  /*   Background Body */
body {
  background: #f9fbfd; }
  body[data-background-color="bg1"] {
    background: #f9fbfd; }
  body[data-background-color="bg2"] {
    background: #ffffff; }
  body[data-background-color="bg3"] {
    background: #f1f1f1; }
  body[data-background-color="dark"] {
    background: #1a2035; }
    body[data-background-color="dark"] .main-header {
      box-shadow: 0px 0px 5px #121727; }
    body[data-background-color="dark"] .main-panel {
      color: rgba(169, 175, 187, 0.82) !important; }
      body[data-background-color="dark"] .main-panel label {
        color: #fff !important; }
    body[data-background-color="dark"] .card, body[data-background-color="dark"] .row-card-no-pd, body[data-background-color="dark"] .list-group-item, body[data-background-color="dark"] .timeline > li > .timeline-panel {
      background: #202940; }
    body[data-background-color="dark"] .card-pricing2 {
      background: #202940 !important; }
    body[data-background-color="dark"] .row-card-no-pd [class*=col] .card:before {
      background: rgba(181, 181, 181, 0.1) !important; }
    body[data-background-color="dark"] .card .card-header, body[data-background-color="dark"] .card .card-footer, body[data-background-color="dark"] .card .card-action, body[data-background-color="dark"] .card-profile .user-stats [class^="col"], body[data-background-color="dark"] .timeline > li > .timeline-panel, body[data-background-color="dark"] .table td, body[data-background-color="dark"] .table th, body[data-background-color="dark"] .breadcrumbs, body[data-background-color="dark"] .separator-dashed, body[data-background-color="dark"] .separator-solid, body[data-background-color="dark"] .separator-dot, body[data-background-color="dark"] .list-group .list-group-item, body[data-background-color="dark"] .conversations .messages-form, body[data-background-color="dark"] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item, body[data-background-color="dark"] .page-with-aside .page-aside, body[data-background-color="dark"] .mail-wrapper .mail-content .email-head, body[data-background-color="dark"] .mail-wrapper .mail-content .email-sender, body[data-background-color="dark"] .main-panel .page-divider {
      border-color: rgba(181, 181, 181, 0.1) !important; }
    body[data-background-color="dark"] .timeline > li > .timeline-panel:before {
      border-left-color: rgba(181, 181, 181, 0.1);
      border-right-color: rgba(181, 181, 181, 0.1); }
    body[data-background-color="dark"] .timeline > li > .timeline-panel:after {
      border-left-color: #202940;
      border-right-color: #202940; }
    body[data-background-color="dark"] .page-title, body[data-background-color="dark"] .breadcrumbs li a {
      color: rgba(169, 175, 187, 0.82); }
    body[data-background-color="dark"] .page-category {
      color: #828282; }
    body[data-background-color="dark"] .card-title, body[data-background-color="dark"] .card-title a, body[data-background-color="dark"] .card-title a:hover, body[data-background-color="dark"] .card-title a:focus {
      color: #fff; }
    body[data-background-color="dark"] .card-category {
      color: #8b92a9; }
    body[data-background-color="dark"] .card-default, body[data-background-color="dark"] .card-primary, body[data-background-color="dark"] .card-secondary, body[data-background-color="dark"] .card-info, body[data-background-color="dark"] .card-success, body[data-background-color="dark"] .card-warning, body[data-background-color="dark"] .card-danger {
      color: #fff; }
      body[data-background-color="dark"] .card-default .card-title, body[data-background-color="dark"] .card-default .card-category, body[data-background-color="dark"] .card-primary .card-title, body[data-background-color="dark"] .card-primary .card-category, body[data-background-color="dark"] .card-secondary .card-title, body[data-background-color="dark"] .card-secondary .card-category, body[data-background-color="dark"] .card-info .card-title, body[data-background-color="dark"] .card-info .card-category, body[data-background-color="dark"] .card-success .card-title, body[data-background-color="dark"] .card-success .card-category, body[data-background-color="dark"] .card-warning .card-title, body[data-background-color="dark"] .card-warning .card-category, body[data-background-color="dark"] .card-danger .card-title, body[data-background-color="dark"] .card-danger .card-category {
        color: #fff; }
    body[data-background-color="dark"] .nav-pills .nav-link:not(.active) {
      background: #fff; }
    body[data-background-color="dark"] .card-pricing .specification-list li {
      border-color: #373d4c; }
    body[data-background-color="dark"] .input-group-text {
      border-color: #2f374b !important;
      background-color: #1f283e;
      color: #fff; }
    body[data-background-color="dark"] .input-solid {
      background: #363b4c !important;
      border-color: #363b4c !important; }
    body[data-background-color="dark"] .list-group-messages .list-group-item-title a, body[data-background-color="dark"] .list-group .list-group-item-text {
      color: inherit; }
    body[data-background-color="dark"] .footer {
      border-top: 1px solid #293247;
      background: #1f283e; }
    body[data-background-color="dark"] .form-control, body[data-background-color="dark"] .form-group-default, body[data-background-color="dark"] .select2-container--bootstrap .select2-selection {
      background-color: #1a2035;
      color: #fff;
      border-color: #2f374b; }
    body[data-background-color="dark"] .bootstrap-tagsinput {
      background: transparent; }
    body[data-background-color="dark"] .selectgroup-button {
      border: 1px solid #2f374b; }
    body[data-background-color="dark"] .conversations .message-header {
      background: #1a2035;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07); }
    body[data-background-color="dark"] .conversations .conversations-content {
      color: #575962;
      border-color: #2e364a; }
    body[data-background-color="dark"] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread {
      background: #1f283e !important; }
    body[data-background-color="dark"] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item:hover {
      background: #171e2f !important; }
    body[data-background-color="dark"] .page-with-aside .page-aside .aside-nav .nav > li:hover, body[data-background-color="dark"] .page-with-aside .page-aside .aside-nav .nav > li:focus, body[data-background-color="dark"] .page-with-aside .page-aside .aside-nav .nav > li.active {
      background: rgba(0, 0, 0, 0.03); }
    body[data-background-color="dark"] .page-with-aside .page-aside .aside-nav .nav > li.active > a {
      color: #b9babf !important; }
    body[data-background-color="dark"] .board {
      color: #575962; }

/*   Background   */
.bg-dark {
  background-color: #1a2035 !important; }

.bg-dark2 {
  background-color: #1f283e !important; }

.bg-primary {
  background-color: #1572E8 !important; }

.bg-primary2 {
  background-color: #1269DB !important; }

.bg-secondary {
  background-color: #6861CE !important; }

.bg-secondary2 {
  background-color: #5C55BF !important; }

.bg-info {
  background-color: #48ABF7 !important; }

.bg-info2 {
  background-color: #3697E1 !important; }

.bg-success {
  background-color: #31CE36 !important; }

.bg-success2 {
  background-color: #2BB930 !important; }

.bg-warning {
  background-color: #FFAD46 !important; }

.bg-warning2 {
  background-color: #FF9E27 !important; }

.bg-danger {
  background-color: #F25961 !important; }

.bg-danger2 {
  background-color: #EA4d56 !important; }

.bg-grey1 {
  background: #f9fbfd !important; }

.bg-grey2 {
  background: #f1f1f1; }

.bg-dark-gradient {
  background: #1f283e !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #0A0B11, #1f283e) !important;
  background: linear-gradient(-45deg, #0A0B11, #1f283e) !important; }

.bg-primary-gradient {
  background: #1572E8 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #06418E, #1572E8) !important;
  background: linear-gradient(-45deg, #06418E, #1572E8) !important; }

.bg-secondary-gradient {
  background: #6861CE !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #2A20AC, #6861CE) !important;
  background: linear-gradient(-45deg, #2A20AC, #6861CE) !important; }

.bg-info-gradient {
  background: #48ABF7 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #0A5A97, #48ABF7) !important;
  background: linear-gradient(-45deg, #0A5A97, #48ABF7) !important; }

.bg-success-gradient {
  background: #31CE36 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #179D08, #31CE36) !important;
  background: linear-gradient(-45deg, #179D08, #31CE36) !important; }

.bg-warning-gradient {
  background: #FFAD46 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #E1810B, #FFAD46) !important;
  background: linear-gradient(-45deg, #E1810B, #FFAD46) !important; }

.bg-danger-gradient {
  background: #F25961 !important;
  background: -webkit-linear-gradient(legacy-direction(-45deg), #E80A15, #F25961) !important;
  background: linear-gradient(-45deg, #E80A15, #F25961) !important; }



/*

 =========================================================
 * Replace styles
 =========================================================
 * By Muhammad Afifudin

*/

.btn-toggle {
  font-size: 21px !important;
}

.main-panel > .content {
  min-height: calc(100% - 130px);
  &.dashboard {
    height: calc(100% - 130px);
  }
}

.dropdown-user {
  width: 240px;
}

.user-box .u-text {
  flex-grow: 1;
  overflow: hidden;
  h4, p {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }
}

.navbar-header {
  transition: all .5s, background .1s;
  @media screen and (max-width: 991.98px) {
    width: auto;
    right: 0;
    transform: none !important;
    z-index: 1010;
    &[data-background-color].navbar-header-customized-background {
      background: transparent !important;
      transition: all .5s, background 1s;
    }
  }
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #7670d2 !important;
}

.animated {
  animation-duration: .5s;
}

.logo-header {
  font-size: 1.25rem;
}

.popover {
  font-size: 12px;
  .popover-header {
    font-size: 12px;
    padding-bottom: 0;
  }
}

.dropdown-menu.quick-actions {
  @media (max-width: 575.98px) {
    transform: translateX(30px);
    &::after {
      transform: translateX(-30px);
    }
  }
}

.curves-shadow:before {
  background-image: url('../img/curves-shadow.png');
}

.card {
  .card-footer {
    line-height: unset;
  }
  &-light .card-footer {
    line-height: unset;
  }
}
